import {
  PrimaryButton,
  DetailsList,
  Label,
  Stack,
  StackItem,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  TextField,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  ITextFieldStyles,
  IconButton,
  SelectionMode,
  Modal,
  mergeStyleSets,
  NormalPeoplePicker,
  IPersonaProps,
  ChoiceGroup,
  IChoiceGroupOption,
  mergeStyles,
  Persona,
  PersonaSize,
  Checkbox,
  MessageBar,
  MessageBarType,
  ITooltipHostStyles,
  TooltipHost,
  ILabelStyles,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./newProject.module.scss";
import { IRecipientsManagement } from "../../../common/models/IRecipientsManagement";
import ProjectService from "../../../common/api-services/ProjectServices";
import GraphService from "../../../common/authProvider/GraphService";
import { IProject } from "../../../common/models/IProject";
import CommonService from "../../../common/api-services/CommonService";
import { ICommon } from "../../../common/models/ICommon";
import { IAzureUserInformation } from "../../../common/models/IUserDetails";
import { CompanyDirectoryIcon } from "@fluentui/react-icons-mdl2";
import { IReportDocument } from "../../../common/models/IReportDocument";

const NewProject = () => {
  const calloutProps = { gapSpace: 0 };

  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
    },
    archiveContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "120px",
      minWidth: "250px",
      borderRadius: "25px",
    },
  });

  const service = useMemo(() => new ProjectService(), []);
  const graphService = useMemo(() => new GraphService(), []);
  const { projectId = "" } = useParams();
  const [project, setProject] = useState<any>({
    status: "Draft",
    documents: [],
  });
  const [currentUser, setCurrentUser] = useState<IAzureUserInformation>();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [itemsAccessManagement, setItemsAccessManagement] = useState<any[]>([]);
  const [editPermission, isEditPermission] = useState<boolean>(false);
  const [deletePermission, isDeletePermission] = useState<boolean>(false);
  const [sentReport, isSentReport] = useState<boolean>(false);
  const [sentTac, isSentTac] = useState<boolean>(false);
  const [recipientItem, setRecipientItem] = useState<any>();
  const [deletePermissionItem, setDeletePermissionItem] = useState<any>();
  const [deleteRecipient, isDeleteRecipient] = useState<boolean>(false);
  const [deleteRecipientItem, setDeleteRecipientItem] = useState<any>();
  const [showPanel, isShowPanel] = useState<boolean>(false);
  const [showSaveBtn, isShowSaveBtn] = useState<boolean>(false);
  const [numberSelectedRecipients, setNumberSelectedRecipients] =
    useState<number>(0);
  const [indeterminateAutoTAC, isIndeterminateAutoTAC] =
    useState<boolean>(false);
  const [checkedTAC, isCheckedTAC] = useState<boolean>(false);
  const [selectAllcheckBoxTAC, isSelectAllcheckBoxTAC] =
    useState<boolean>(false);
  const [indeterminateAutoReport, isIndeterminateAutoReport] =
    useState<boolean>(false);
  const [checkedReport, isCheckedReport] = useState<boolean>(false);
  const [disableAllCheckboxReport, isDisableAllCheckboxReport] =
    useState<boolean>(false);
  const [selectAllcheckBoxReport, isSelectAllcheckBoxReport] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageBarType, setMessageBarType] = useState<MessageBarType>();
  const [messageRecipient, setMessageRecipient] = useState<string>("");
  const [messageBarTypeRecipient, setMessageBarTypeRecipient] =
    useState<MessageBarType>();
  const [messagePermission, setMessagePermission] = useState<string>("");
  const [messageBarTypePermission, setMessageBarTypePermission] =
    useState<MessageBarType>();
  const [messageReport, setMessageReport] = useState<string>("");
  const [messageBarTypeReport, setMessageBarTypeReport] =
    useState<MessageBarType>();
  const [errorMessageFieldRequired, setErrorMessageFieldRequired] =
    useState<string>("");

  const [itemsAccessManagementModal, setItemsAccessManagementModal] = useState<
    any[]
  >([]);

  const [currentSelectedMembers, setCurrentSelectedMembers] = React.useState<
    any[]
  >([]);
  const [
    isModalSendTacOrReportOpen,
    { setTrue: showModalSendTacOrReport, setFalse: hideModalSendTacOrReport },
  ] = useBoolean(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isModalArchiveOpen,
    { setTrue: showArchiveModal, setFalse: hideArchiveModal },
  ] = useBoolean(false);
  const [
    isModalReactivateOpen,
    { setTrue: showReactivateModal, setFalse: hideReactivateModal },
  ] = useBoolean(false);
  const [
    isModalRecipientOpen,
    { setTrue: showRecipientModal, setFalse: hideRecipientModal },
  ] = useBoolean(false);
  const [itemsRecipientsManagement, setItemsRecipientsManagement] = useState<
    IRecipientsManagement[]
  >([]);
  const [reportDocumentManagement, setReportDocumentManagement] = useState<
    IReportDocument[]
  >([]);
  const [errorMessageEmail, setErrorMessageEmail] = useState<string>("");
  const [recipientEmail, setRecipientEmail] = useState<string>("");
  const [recipientManagementModal, setRecipientManagementModal] = useState<
    IRecipientsManagement[]
  >([]);
  const [
    isModalProcessOpen,
    { setTrue: showProcessModal, setFalse: hideProcessModal },
  ] = useBoolean(false);
  const serviceCommon = useMemo(() => new CommonService(), []);
  const [companies, setCompanies] = useState<IDropdownOption[]>([]);
  const [ownersCount, setOwnersCount] = useState<number>(0);
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/home`;
    navigate(path);
  };

  const onDropDownChange = (
    event: React.FormEvent<HTMLDivElement>,
    field: string,
    option?: IDropdownOption
  ): void => {
    let obj: any = project ? project : {};
    obj[field] = option?.key;
    setProject(obj);
    if (field == "company") {
      setSelectedCompany(obj.company);
    }
  };
  const onChangeTxtField = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    newValue?: string
  ): void => {
    if (field === "email") {
      setRecipientEmail(newValue ? newValue : "");
    } else {
      let obj: any = project ? project : {};
      obj[field] = newValue;
      setProject({ ...obj });
    }
  };
  const onSubmitProject = () => {
    if (
      project.name &&
      project.category &&
      project.client &&
      project.target &&
      project.company
    ) {
      setErrorMessageFieldRequired("");
      if (projectId != "") {
        service.Update(project).then((result: any) => {
          if (result?.error) {
            setMessage(`Error: ${result?.error}`);
            setMessageBarType(MessageBarType.error);
          } else {
            setProject(project);
            setMessage(`Project ${project.name} updated successfully`);
            setMessageBarType(MessageBarType.success);
          }
          setTimeout(resetMessage, 10000);
        });
      } else {
        service
          .Create({
            ...project,
            owner: currentUser?.email + ";",
            contributor: "",
            isDeleted: false,
          })
          .then((result: any) => {
            if (result?.error) {
              setMessage(`Error: ${result?.error}`);
              setMessageBarType(MessageBarType.error);
            } else {
              setProject(result);
              navigate(`/project/${result.projectId}`);
              setMessage(`Project ${result.name} created successfully`);
              setMessageBarType(MessageBarType.success);
            }
            setTimeout(resetMessage, 10000);
          });
      }
    } else {
      setErrorMessageFieldRequired("Field is required");
    }
  };
  const onAddProjectPermission = () => {
    let usersToSend = itemsAccessManagementModal;
    itemsAccessManagement.forEach((element) => {
      if (
        itemsAccessManagementModal.filter((e) => e.mail == element.mail)
          .length == 0
      ) {
        usersToSend.push(element);
      }
    });
    let owners = usersToSend
      .map((item) => {
        return item.projectRole == "Owner" ? item.mail : "";
      })
      .filter((n) => n)
      .join(";");

    let contributors = usersToSend
      .map((item) => {
        return item.projectRole == "Member" ? item.mail : "";
      })
      .filter((n) => n) //remove empty entries
      .join(";"); // join email adresses

    let obj = { ...project, contributor: contributors, owner: owners };
    setProject(obj);
    hideModal();
    setItemsAccessManagementModal([]);
    service.Update(obj as IProject).then((data: any) => {
      if (data?.error) {
        setMessagePermission(`Error: ${data?.error}`);
        setMessageBarTypePermission(MessageBarType.error);
      } else {
        setItemsAccessManagement(usersToSend);
        let currentSelectedUsers: IPersonaProps[] = [];
        usersToSend.forEach((element: { [x: string]: any }) => {
          let option = {
            key: element["mail"] || 0,
            text: element["displayName"] || "",
          };
          currentSelectedUsers.push(option);
        });
        setCurrentSelectedMembers(currentSelectedUsers);
        if (editPermission) {
          setMessagePermission(
            `Project members and owners updated successfully`
          );
        } else {
          setMessagePermission(`Project members and owners added successfully`);
        }

        setMessageBarTypePermission(MessageBarType.success);
      }
      setTimeout(resetMessagePermission, 10000);
    });
  };
  const onDeleteProjectUserPermission = (item: any) => {
    let obj = project;
    //Owner
    if (item.projectRole === options[0].key) {
      //Check if there it at least one owner. todo
      obj = { ...obj, owner: obj.owner.replace(item.mail, "") };
      setProject(obj);
      resetModal();
      service
        .DeleteUserPermission(obj as IProject, item.mail)
        .then((data: any) => {
          if (data?.error) {
            setMessagePermission(`Error: ${data?.error}`);
            setMessageBarTypePermission(MessageBarType.error);
          } else {
            setMessagePermission(
              `Project member or owner removed successfully`
            );
            setMessageBarTypePermission(MessageBarType.success);
          }

          setTimeout(resetMessagePermission, 10000);
        });
    } else {
      obj = { ...obj, contributor: obj.contributor.replace(item.mail, "") };
      setProject(obj);
      service
        .DeleteUserPermission(obj as IProject, item.mail)
        .then((data: any) => {
          if (data?.error) {
            setMessagePermission(`Error: ${data?.error}`);
            setMessageBarTypePermission(MessageBarType.error);
          } else {
            setMessagePermission(
              `Project member or owner removed successfully`
            );
            setMessageBarTypePermission(MessageBarType.success);
          }
          resetModal();
          setTimeout(resetMessagePermission, 10000);
        });
    }
  };
  const onStartProcess = () => {
    let obj = project as IProject;
    obj.status = "Active";
    service.StartProcess(obj as IProject).then((data: any) => {
      if (data?.error) {
        setMessage(`Error: ${data?.error}`);
        setMessageBarType(MessageBarType.error);
      } else {
        setMessage(`Project started successfully`);
        setMessageBarType(MessageBarType.success);
      }
      setTimeout(resetMessage, 10000);
      hideProcessModal();
    });
  };
  const _onFormatDate = (date: string): string => {
    const myDate = new Date(date);
    return (
      ("0" + myDate.getDate()).slice(-2) +
      "." +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "." +
      myDate.getFullYear() +
      ", " +
      ("0" + myDate.getHours()).slice(-2) +
      ":" +
      ("0" + myDate.getMinutes()).slice(-2)
    );
  };
  function doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }
  function listContainsPersona(
    persona: IPersonaProps,
    personas: IPersonaProps[]
  ) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
  }
  function removeDuplicates(
    personas: IPersonaProps[],
    possibleDupes: IPersonaProps[]
  ) {
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes)
    );
  }
  const filterPersonasByText = async (
    filterText: string
  ): Promise<IPersonaProps[]> => {
    let usersL: any = await graphService.getUsersByName(filterText);
    let peoples: IPersonaProps[] = [];
    usersL.value.forEach((element: { [x: string]: any }) => {
      let option = {
        key: element["mail"] || 0,
        text: element["displayName"] || "",
      };
      peoples.push(option);
    });
    return peoples;
  };
  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };
  const onItemsChange = async (items?: any[]): Promise<void> => {
    let result: IPersonaProps[] = [];
    let accessItems: any[] = [];
    if (items) {
      let allUsers = await graphService.getUserBatch(
        items.map((u) => {
          return u.key;
        })
      );
      items.forEach((element) => {
        let temp = allUsers.filter((item: { mail: string }) => {
          return (item.mail as string) == element.key;
        });
        result.push(temp[0]);
        let accessItem = itemsAccessManagementModal.filter((item: any) => {
          return item.mail == temp[0].mail;
        });
        if (accessItem.length == 0) {
          accessItem = { ...temp[0], projectRole: "Member" };
          accessItems.push(accessItem);
        } else {
          accessItems.push(accessItem[0]);
        }
      });
      setItemsAccessManagementModal(accessItems);
    }
  };
  const onFilterChanged = async (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = await filterPersonasByText(
        filterText
      );
      currentPersonas = currentSelectedMembers;
      filteredPersonas = removeDuplicates(
        filteredPersonas,
        currentPersonas ?? []
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };
  const options: IChoiceGroupOption[] = [
    { key: "Owner", text: "Owner", styles: { field: { marginBottom: 5 } } },
    {
      key: "Member",
      text: "Member",
      styles: { field: { marginLeft: "15px", marginBottom: 5 } },
      // disabled: ownersCount == 1,
    },
  ];
  const _onChangeMemberPermission = (
    item: any,
    option?: IChoiceGroupOption
  ) => {
    let result: any[] = [];
    itemsAccessManagementModal.forEach((element) => {
      if (item.mail == element.mail) {
        element = { ...element, projectRole: option?.key };
        result.push(element);
      } else {
        result.push(element);
      }
    });
    setItemsAccessManagementModal(result);
  };

  const columnStyle = mergeStyles({
    alignSelf: "center",
  });

  const columnsAccessManagement: IColumn[] = [
    {
      key: "column1",
      name: "Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 200,
    },
    {
      key: "column3",
      name: "Position",
      fieldName: "jobTitle",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      data: "string",
      isPadded: false,
    },
    {
      key: "column4",
      name: "Platform",
      fieldName: "department",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      data: "string",
      isPadded: false,
    },
    {
      key: "column5",
      name: "Project role",
      fieldName: "projectRole",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,

      data: "string",
      isPadded: false,
    },
    {
      key: "column8",
      name: "",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <>
            {project.owner?.includes(currentUser?.email) && (
              <Stack horizontal horizontalAlign="end">
                {!(ownersCount == 1 && item.projectRole == "Owner") &&
                  project.status != "Archived" && (
                    <TooltipHost
                      content="Edit user permission"
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton
                        iconProps={{ iconName: "Edit" }}
                        className={styles.actionIconButton}
                        onClick={() => {
                          isEditPermission(true);
                          setItemsAccessManagementModal([item]);
                          showModal();
                        }}
                      />
                    </TooltipHost>
                  )}
                {!(ownersCount == 1 && item.projectRole == "Owner") &&
                  currentUser?.email != item.mail &&
                  project.status != "Archived" && (
                    <TooltipHost
                      content="Delete user permission"
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton
                        iconProps={{ iconName: "Delete" }}
                        className={styles.actionIconButton}
                        onClick={() => {
                          setDeletePermissionItem(item);
                          isDeletePermission(true);
                          showModal();
                        }}
                      />
                    </TooltipHost>
                  )}
              </Stack>
            )}
          </>
        );
      },
    },
  ];
  const columnsAccessManagementModal: IColumn[] = [
    {
      key: "column1",
      name: "",
      fieldName: "text",
      minWidth: 100,
      maxWidth: 150,
      className: columnStyle,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="start">
            <Persona
              text={item.displayName}
              secondaryText={item.jobTitle}
              size={PersonaSize.size32}
            />
          </Stack>
        );
      },
    },
    {
      key: "column5",
      name: "Project role",
      fieldName: "projectRole",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,

      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="start">
            <ChoiceGroup
              options={options}
              styles={{ flexContainer: { display: "flex" } }}
              defaultSelectedKey={editPermission ? item.projectRole : "Member"}
              onChange={(ev, option) => {
                _onChangeMemberPermission(item, option);
              }}
            ></ChoiceGroup>
          </Stack>
        );
      },
    },
  ];
  const columnsRecipientsManagement: IColumn[] = [
    {
      key: "column1",
      name: "E-mail",
      fieldName: "email",
      minWidth: 80,
      maxWidth: 300,
      isRowHeader: true,
      data: "string",
    },
    {
      key: "column2",
      name: "autoSendTermsCondition",
      fieldName: "autoSendTermsCondition",
      minWidth: 25,
      maxWidth: 25,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center" style={{ paddingTop: 3 }}>
            <TooltipHost
              content="Automatic distribution of e-mail with invitation link to accept Roland Berger document access terms"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Checkbox
                defaultChecked={item.autoSendTermsCondition}
                checked={item.autoSendTermsCondition}
                disabled={
                  project.status != "Draft" || item.termsConditionAccepted
                }
                onChange={(ev, checked) => {
                  onChangeCheckBox(item, "tac", ev, checked);
                }}
              />
            </TooltipHost>
          </Stack>
        );
      },
      onRenderHeader: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center" style={{ paddingTop: 12 }}>
            <TooltipHost
              content="Automatic distribution of e-mail with invitation link to accept Roland Berger document access terms (for all recipients)"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Checkbox
                indeterminate={indeterminateAutoTAC}
                checked={checkedTAC}
                onChange={(ev, checked) => {
                  if (checked) {
                    isCheckedTAC(true);
                    isSelectAllcheckBoxTAC(true);
                    showRecipientModal();
                  } else {
                    onChangeTAC(ev, checked);
                  }
                }}
                disabled={project.status != "Draft"}
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
    {
      key: "column3",
      name: "Distribution of invitation link",
      fieldName: "sentTermsCondition",
      minWidth: 120,
      maxWidth: 200,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center">
            {/* {!item.sentTermsCondition && item.acceptanceDate == undefined && ( */}
            {item.acceptanceDate == undefined && project.status == "Active" && (
              <>
                <TooltipHost
                  content="Send e-mail with invitation link to accept Roland Berger document access terms"
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={mailIcon}
                    title="Send e-mail with invitation link to accept Roland Berger document access terms"
                    className={styles.actionIconButton}
                    // onClick={() => onSendNotificationOrReport(item, false)}
                    onClick={() => {
                      isSentTac(true);
                      setRecipientItem(item);
                      showModalSendTacOrReport();
                    }}
                    disabled={project.status == "Archived"}
                  />
                </TooltipHost>
                <TooltipHost
                  content="Copy invitation link to accept Roland Berger document access terms"
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={copyIcon}
                    title="Copy invitation link to accept Roland Berger document access terms"
                    className={styles.actionIconButton}
                    onClick={() => onCopyNotificationOrReportLink(item, false)}
                    disabled={project.status == "Archived"}
                  />
                </TooltipHost>
              </>
            )}
            {item.sentTermsCondition && item.acceptanceDate == undefined && (
              <TooltipHost
                content="E-mail with invitation link sent"
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton
                  iconProps={completedIconGray}
                  style={{ width: 24, height: 24 }}
                />
              </TooltipHost>
            )}
            {item.acceptanceDate != undefined && (
              <TooltipHost
                content="Terms and conditions accepted"
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton iconProps={completedIconGreen} />
              </TooltipHost>
            )}
          </Stack>
        );
      },
    },
    {
      key: "column4",
      name: "Acceptance status",
      fieldName: "acceptanceDate",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        if (item.acceptanceDate) return _onFormatDate(item.acceptanceDate);
      },
    },
    {
      key: "column5",
      name: "autoSendReport",
      fieldName: "autoSendReport",
      minWidth: 25,
      maxWidth: 25,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center" style={{ paddingTop: 3 }}>
            <TooltipHost
              content="Automatic send of report"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Checkbox
                defaultChecked={item.autoSendReport}
                checked={item.autoSendReport}
                onChange={(ev, checked) => {
                  onChangeCheckBox(item, "report", ev, checked);
                }}
                disabled={
                  item.acceptanceDate != undefined ||
                  project.status == "Archived" ||
                  project.documents == undefined ||
                  project.documents == null ||
                  project.documents.length == 0
                }
              ></Checkbox>
            </TooltipHost>
          </Stack>
        );
      },
      onRenderHeader: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center" style={{ paddingTop: 12 }}>
            <TooltipHost
              content="Automatic send of report (for all recipients)"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Checkbox
                indeterminate={indeterminateAutoReport}
                checked={checkedReport}
                onChange={(ev, checked) => {
                  if (checked) {
                    isCheckedReport(true);
                    isSelectAllcheckBoxReport(true);
                    showRecipientModal();
                  } else {
                    onChangeReport(ev, checked);
                  }
                }}
                disabled={
                  disableAllCheckboxReport ||
                  project.status == "Archived" ||
                  project.documents == undefined ||
                  project.documents == null ||
                  project.documents.length == 0
                }
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
    {
      key: "column6",
      name: "Send report",
      fieldName: "sendReport",
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="center">
            {item.acceptanceDate != undefined &&
              (project.documents != undefined || project.documents != null) &&
              project.status == "Active" && (
                <>
                  <TooltipHost
                    content="Send report"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      iconProps={mailIcon}
                      title="Send report"
                      className={styles.actionIconButton}
                      // onClick={() => onSendNotificationOrReport(item, true)}
                      onClick={() => {
                        isSentReport(true);
                        setRecipientItem(item);
                        showModalSendTacOrReport();
                      }}
                      disabled={
                        project.status == "Archived" ||
                        project.documents == undefined ||
                        project.documents == null ||
                        project.documents.length == 0
                      }
                    />
                  </TooltipHost>
                  <TooltipHost
                    content="Copy report link"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      iconProps={copyIcon}
                      className={styles.actionIconButton}
                      onClick={() => onCopyNotificationOrReportLink(item, true)}
                      disabled={
                        project.status == "Archived" ||
                        project.documents == undefined ||
                        project.documents == null ||
                        project.documents.length == 0
                      }
                    />
                  </TooltipHost>
                  {(item.sentReport || (item.acceptanceDate != undefined && item.autoSendReport)) && (
                    <TooltipHost
                      content="Report email sent"
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton iconProps={completedIconGreen} />
                    </TooltipHost>
                  )}
                </>
              )}
          </Stack>
        );
      },
    },
    {
      key: "column7",
      name: "Download date",
      fieldName: "",
      minWidth: 100,
      // maxWidth: 200,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      isResizable: true,
      onRender: (item: any) => {
        return (
          item.documents != null &&
          item.documents.map((x: any) => {
            if (x.isDownloaded)
              return (
                <div key={x.id}>
                  {x.fileName} {_onFormatDate(x.downloadDate)}
                </div>
              );
          })
        );
      },
    },
    {
      key: "column8",
      name: "",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="end">
            <TooltipHost
              content="Delete recipient"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              {project.status != "Archived" &&
                (project.owner?.includes(currentUser?.email) ||
                  project.contributor?.includes(currentUser?.email)) &&
                !item.termsConditionAccepted && (
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    className={styles.actionIconButton}
                    onClick={() => {
                      setDeleteRecipientItem(item);
                      isDeleteRecipient(true);
                      showRecipientModal();
                    }}
                  />
                )}
            </TooltipHost>
          </Stack>
        );
      },
    },
  ];
  const columnsRecipientsManagementModal: IColumn[] = [
    {
      key: "column1",
      name: "E-mail",
      fieldName: "email",
      minWidth: 100,
      maxWidth: 150,
      className: columnStyle,
    },
  ];
  const columnsReportDocument: IColumn[] = [
    {
      key: "column1",
      name: "Name",
      fieldName: "fileName",
      minWidth: 300,
      maxWidth: 400,
    },
    {
      key: "column2",
      name: "Upload date",
      fieldName: "uploadDate",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isPadded: false,
      onRender: (item: any) => {
        return _onFormatDate(item.uploadDate);
      },
    },
    {
      key: "column3",
      name: "Upload by",
      fieldName: "uploadedBy",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isPadded: false,
    },

    {
      key: "column4",
      name: "",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <>
            <Stack horizontal horizontalAlign="end">
              {project.owner?.includes(currentUser?.email) &&
                project.status != "Archived" &&
                item.isDisabled && (
                  <TooltipHost
                    content="Enable report document"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      iconProps={{ iconName: "View" }}
                      className={styles.actionIconButton}
                      onClick={() => {
                        onDisableDocument(
                          item.fileName,
                          project.projectId,
                          false
                        );
                      }}
                    />
                  </TooltipHost>
                )}
              {project.owner?.includes(currentUser?.email) &&
                project.status != "Archived" &&
                item.isDisabled != true && (
                  <TooltipHost
                    content="Disable report document"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      iconProps={{ iconName: "Hide3Icon" }}
                      className={styles.actionIconButton}
                      onClick={() => {
                        onDisableDocument(
                          item.fileName,
                          project.projectId,
                          true
                        );
                      }}
                    />
                  </TooltipHost>
                )}
              {project.owner?.includes(currentUser?.email) &&
                project.status != "Archived" &&
                project.status == "Draft" && (
                  <TooltipHost
                    content="Delete report document"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      iconProps={{ iconName: "Delete" }}
                      className={styles.actionIconButton}
                      onClick={() => {
                        onDeleteProjectDocument(item as IReportDocument);
                      }}
                    />
                  </TooltipHost>
                )}
              {project.status != "Archived" && (
                <TooltipHost
                  content="Download report document"
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={{ iconName: "DownloadDocument" }}
                    className={styles.actionIconButton}
                    onClick={() => {
                      if (item.fileLink)
                        onDownloadDocument(item.fileLink, item.fileName);
                    }}
                    disabled={item.isDisabled}
                  />
                </TooltipHost>
              )}
            </Stack>
          </>
        );
      },
    },
  ];
  const dropdownStyles: Partial<IDropdownStyles> = {
    root: { paddingRight: 50, paddingBottom: 10 },
    dropdown: { width: 300 },
  };

  const textfieldStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 350,
      paddingRight: 50,
      paddingBottom: 10,
    },
  };
  const getLabelStyles = (): ILabelStyles => {
    return {
      root: {
        color: "rgb(161, 159, 157)",
      },
    };
  };
  const textfieldmodalStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 350,
      paddingRight: 50,
      paddingBottom: 10,
    },
    fieldGroup: {
      borderBottom: "1px solid rgb(96, 94, 92)",
    },
    field: {
      fontWeight: "bold",
    },
    subComponentStyles: {
      label: getLabelStyles,
    },
  };
  const hiddenFileInput = React.useRef<any>(null);
  const categories: IDropdownOption[] = [
    {
      key: "BS",
      text: "Buy-side",
    },
    {
      key: "SS",
      text: "Sell-side",
    },
    {
      key: "MS",
      text: "Market study",
    },
  ];

  const projectListIcon: IIconProps = {
    iconName: "DoubleChevronLeftIcon",
    styles: {
      root: [
        {
          selectors: {
            ">span": {
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  const completedIconGreen: IIconProps = {
    iconName: "CompletedIcon",
    styles: {
      root: [
        {
          fontSize: 20,
          height: 24,
          width: 24,
          color: "green",
          selectors: {
            ">span": {
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  const completedIconGray: IIconProps = {
    iconName: "CompletedIcon",
    styles: {
      root: [
        {
          fontSize: 20,
          height: 20,
          width: 20,
          color: "lightgray",
          // selectors: {
          //   ">span": {
          //     // verticalAlign: "top",
          //   },
          // },
        },
      ],
    },
  };
  const circleRingIcon: IIconProps = {
    iconName: "CircleRingIcon",
    styles: {
      root: [
        {
          fontSize: 20,
          height: 24,
          width: 24,
          color: "lightgray",
          selectors: {
            ">span": {
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  const mailIcon: IIconProps = {
    iconName: "MailIcon",
    styles: {
      root: [
        {
          fontSize: 20,
          height: 20,
          width: 20,
        },
      ],
    },
  };
  const copyIcon: IIconProps = {
    iconName: "CopyIcon",
    styles: {
      root: [
        {
          fontSize: 20,
          height: 20,
          width: 20,
        },
      ],
    },
  };
  const addRecipient = () => {
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(recipientEmail)) {
      setErrorMessageEmail("E-mail not valid");
    } else {
      setErrorMessageEmail("");
      let recipientCheckOnModal = recipientManagementModal.filter((u: any) => {
        return u.email == recipientEmail;
      });
      let recipientCheckOnList = itemsRecipientsManagement.filter((u: any) => {
        return u.email == recipientEmail;
      });
      if (recipientCheckOnModal.length > 0) {
        setErrorMessageEmail("E-mail address already added");
        return;
      }
      if (recipientCheckOnList.length > 0) {
        if (recipientCheckOnList[0].isDeleted) {
          let rec = recipientCheckOnList[0];
          rec.isDeleted = false;
          rec.deletedTime = undefined;
          rec.documents = [];
          setRecipientManagementModal([...recipientManagementModal, rec]);
          setRecipientEmail("");
        } else {
          setErrorMessageEmail("E-mail address already exist in the list");
        }

        return;
      }

      setRecipientManagementModal([
        ...recipientManagementModal,
        {
          recipientId: crypto.randomUUID(),
          email: recipientEmail,
          autoSendReport: false,
          sentReport: false,

          autoSendTermsCondition: false,
          sentTermsCondition: false,
          termsConditionAccepted: false,
          isDeleted: false,
          documents: project.documents,
        },
      ]);
      setRecipientEmail("");
    }
  };

  const onDeleteRecipient = (item: any) => {
    if (project.status == "Draft") {
      let result = project.recipients.filter(function (obj: any) {
        return obj.email != item.email;
      });
      setItemsRecipientsManagement(result);
      let obj = project as IProject;
      obj.recipients = result;
      service.Update(obj).then((data: any) => {
        if (data?.error) {
          setMessageRecipient(`Error: ${data?.error}`);
          setMessageBarTypeRecipient(MessageBarType.error);
        } else {
          setMessageRecipient(`Recipient deleted successfully`);
          setMessageBarTypeRecipient(MessageBarType.success);
        }
        resetModalRecipient();
        setTimeout(resetMessageRecipient, 10000);
      });
    } else {
      if (project.status == "Active") {
        service
          .DeleteRecipient(project.projectId, item.recipientId)
          .then((data: any) => {
            if (data?.error) {
              setMessageRecipient(`Error: ${data?.error}`);
              setMessageBarTypeRecipient(MessageBarType.error);
            } else {
              setMessageRecipient(`Recipient deleted successfully`);
              setMessageBarTypeRecipient(MessageBarType.success);
              getProject();
            }
            resetModalRecipient();
            setTimeout(resetMessageRecipient, 10000);
          });
      }
    }
  };

  const resetModal = () => {
    hideModal();
    isEditPermission(false);
    isDeletePermission(false);
    setDeletePermissionItem(null);

    setItemsAccessManagementModal([]);
  };
  const resetModalRecipient = () => {
    hideRecipientModal();
    setRecipientManagementModal([]);
    isSelectAllcheckBoxReport(false);
    isSelectAllcheckBoxTAC(false);

    // setDeleteRecipientItem(null);
    isDeleteRecipient(false);
  };
  const merge = (a: any, b: any[], predicate = (a: any, b: any) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) =>
      c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)
    );
    return c;
  };
  const onAddRecipientToManagement = () => {
    let res: IRecipientsManagement[] = [];
    res = merge(
      recipientManagementModal,
      itemsRecipientsManagement,
      (a, b) => a.recipientId === b.recipientId
    );

    setItemsRecipientsManagement(res);
    let obj = project as IProject;
    obj.recipients = res;
    setProject(obj);
    service.Update(obj).then((data: any) => {
      if (data?.error) {
        setMessageRecipient(`Error: ${data?.error}`);
        setMessageBarTypeRecipient(MessageBarType.error);
      } else {
        setMessageRecipient(`Recipients added successfully`);
        setMessageBarTypeRecipient(MessageBarType.success);
      }
      setTimeout(resetMessageRecipient, 10000);
    });
    resetModalRecipient();
  };
  const onCopyNotificationOrReportLink = (item: any, report: boolean) => {
    const appSettings = localStorage.getItem("appSettings") as any;
    if (appSettings) {
      const url = report
        ? JSON.parse(appSettings).customerAppUrl +
          "docgen/" +
          project.projectId +
          "/" +
          item.recipientId +
          "/"
        : JSON.parse(appSettings).customerAppUrl +
          "tac/" +
          project.projectId +
          "/" +
          item.recipientId +
          "/";

      navigator.clipboard.writeText(url);
      if (report) {
        item.sentReport = true;
      } else {
        item.sentTermsCondition = true;
      }
      let result = project.recipients;
      result = result.map((e: any) => {
        if (e.recipientId == item.recipientId) {
          return item;
        }
        return e;
      });
      setMessageRecipient(`Invitation link copied to clipboard`);
      setMessageBarTypeRecipient(MessageBarType.success);

      service.Update({ ...project, recipients: result }).then((data: any) => {
        if (data?.error) {
          setMessageRecipient(`Error: ${data?.error}`);
          setMessageBarTypeRecipient(MessageBarType.error);
        } else {
          getProject();
        }
        setTimeout(resetMessageRecipient, 10000);
      });
    }
  };
  const onSendNotificationOrReport = (item: any, report: boolean) => {
    hideModalSendTacOrReport();
    let obj = project;
    obj.recipients = [{ ...item }];
    if (report) {
      service.SendReportEmailToRecipient(obj as IProject).then((data: any) => {
        if (data?.error) {
          setMessageRecipient(`Error: ${data?.error}`);
          setMessageBarTypeRecipient(MessageBarType.error);
        } else {
          setMessageRecipient(`Report sent successfully`);
          setMessageBarTypeRecipient(MessageBarType.success);
          getProject();
        }
      });
    } else {
      service
        .SendNotificationEmailToRecipient(obj as IProject)
        .then((data: any) => {
          if (data?.error) {
            setMessageRecipient(`Error: ${data?.error}`);
            setMessageBarTypeRecipient(MessageBarType.error);
          } else {
            setMessageRecipient(`Notification sent successfully`);
            setMessageBarTypeRecipient(MessageBarType.success);
            getProject();
          }
        });
    }
    setTimeout(resetMessageRecipient, 10000);
  };
  const onChangeTAC = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let result = project.recipients;
    result = result.map((e: any) => {
      e.autoSendTermsCondition = checked ? true : false;
      return e;
    });
    let obj = { ...project, recipients: result };
    if (result) {
      setItemsRecipientsManagement(result);
    }
    isCheckedTAC(checked ? true : false);
    isIndeterminateAutoTAC(false);
    service.Update(obj).then((data: any) => {
      if (data?.error) {
        setMessageRecipient(`Error: ${data?.error}`);
        setMessageBarTypeRecipient(MessageBarType.error);
      } else {
        // setMessageRecipient(`Recipients updated successfully`);
        // setMessageBarTypeRecipient(MessageBarType.success);
        setProject(obj);
        checkAllTac(obj);
      }
      resetModalRecipient();
      setTimeout(resetMessageRecipient, 10000);
    });
  };
  const onChangeReport = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let result = project.recipients;
    result = result.map((e: any) => {
      e.autoSendReport = checked ? true : false;
      return e;
    });
    let obj = { ...project, recipients: result };
    if (result) {
      setItemsRecipientsManagement(result);
    }
    isCheckedReport(checked ? true : false);
    isIndeterminateAutoReport(false);
    service.Update(obj).then((data: any) => {
      if (data?.error) {
        setMessageRecipient(`Error: ${data?.error}`);
        setMessageBarTypeRecipient(MessageBarType.error);
      } else {
        // setMessageRecipient(`Recipients updated successfully`);
        // setMessageBarTypeRecipient(MessageBarType.success);
        setProject(obj);
        checkAllReport(obj);
      }
      resetModalRecipient();
      setTimeout(resetMessageRecipient, 10000);
    });
  };
  const onChangeCheckBox = (
    item: any,
    field: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let result = project.recipients;
    if (field == "tac") {
      result = result.map((p: any) =>
        p.email === item.email ? { ...p, autoSendTermsCondition: checked } : p
      );
    }
    if (field == "report") {
      result = result.map((p: any) =>
        p.email === item.email ? { ...p, autoSendReport: checked } : p
      );
    }
    let obj = { ...project, recipients: result };
    if (result) {
      setItemsRecipientsManagement(result);
    }
    checkAllTac(obj);
    checkAllReport(obj);
    setProject(obj);
    service.Update(obj).then((data: any) => {
      if (data?.error) {
        setMessageRecipient(`Error: ${data?.error}`);
        setMessageBarTypeRecipient(MessageBarType.error);
      } else {
        // setMessageRecipient(`Recipients updated successfully`);
        // setMessageBarTypeRecipient(MessageBarType.success);
      }
      setTimeout(resetMessageRecipient, 10000);
    });
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var file = e.target.files![0];
    if (file) {
      const exist = reportDocumentManagement.some(
        (x) => x.fileName === file.name
      );
      if (exist) {
        setMessageBarTypeReport(MessageBarType.warning);
        setMessageReport(
          `Report document with the same file name exists already.`
        );
        setTimeout(resetMessageReport, 20000);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          var arrayBuffer = reader.result as ArrayBuffer;
          var base64String = btoa(
            new Uint8Array(arrayBuffer).reduce(function (data, byte) {
              return data + String.fromCharCode(byte);
            }, "")
          );
          let doc: IReportDocument = {
            fileName: file.name.trim(),
            fileData: base64String,
            uploadDate: new Date(),
            uploadedBy: currentUser?.displayName,
          };
          const exist = reportDocumentManagement.some(
            (x) => x.fileName === file.name.trim()
          );
          if (exist) {
          } else {
          }

          service.UploadDocument(doc, project.projectId).then((result: any) => {
            if (result?.error) {
              setMessageReport(`Error: ${result?.error}`);
              setMessageBarTypeReport(MessageBarType.error);
              e.target.files = null;
            } else {
              setMessageReport(`Report uploaded successfully`);
              setMessageBarTypeReport(MessageBarType.success);
              setProject(result);
              setReportDocumentManagement(project.documents);
            }

            setTimeout(resetMessageReport, 20000);
          });
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };
  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const onDownloadDocument = (fileLink: string, fileName: string) => {
    service.DownloadDocument(fileLink).then((data: string) => {
      var blob = b64toBlob(data as string);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    });
  };
  const onDisableDocument = (
    fileName: string,
    projectId: string,
    disable: boolean
  ) => {
    service
      .DisableDocument(fileName, projectId, disable)
      .then((result: any) => {
        if (result?.error) {
          setMessageReport(`Error: ${result?.error}`);
          setMessageBarTypeReport(MessageBarType.error);
        } else {
          if (disable) {
            setMessageReport(`Report document disabled successfully`);
          } else {
            setMessageReport(`Report document enabled successfully`);
          }

          setMessageBarTypeReport(MessageBarType.success);
          setProject(result);
          setReportDocumentManagement(result.documents);
          setItemsRecipientsManagement(result.recipients);
        }
      });
  };

  const onDeleteProjectDocument = (item: IReportDocument) => {
    service.DeleteDocument(item, project.projectId).then((data: any) => {
      if (data?.error) {
        setMessageReport(`Error: ${data?.error}`);
        setMessageBarTypeReport(MessageBarType.error);
      } else {
        setMessageReport(`Document deleted successfully`);
        setMessageBarTypeReport(MessageBarType.success);
        setProject(data);
      }
      setTimeout(resetMessageReport, 10000);
    });
  };
  const resetMessageRecipient = () => {
    setMessageRecipient("");
    setMessageBarTypeRecipient(undefined);
  };
  const resetMessageReport = () => {
    setMessageReport("");
    setMessageBarTypeReport(undefined);
  };
  const resetMessage = () => {
    setMessage("");
    setMessageBarType(undefined);
  };
  const resetMessagePermission = () => {
    setMessagePermission("");
    setMessageBarTypePermission(undefined);
  };
  const getProject = () => {
    if (projectId) {
      service.GetById(projectId).then((data: IProject) => {
        setProject(data);
        if (data.recipients) {
          setItemsRecipientsManagement(data.recipients);
        }
      });
    }
  };
  const onArchiveProject = () => {
    if (project?.status != "Archived") {
      let obj = project;
      obj.status = "Archived";
      service.Update(obj as IProject).then((result: any) => {
        if (result?.error) {
          setMessage(`Error: ${result?.error}`);
          setMessageBarType(MessageBarType.error);
        } else {
          setMessage(`Project archived successfully`);
          setMessageBarType(MessageBarType.success);
        }
        setTimeout(resetMessage, 10000);
        hideArchiveModal();
      });
    }
  };
  const onReactivateProject = () => {
    if (project?.status == "Archived") {
      let obj = project;
      obj.status = "Active";
      service.Update(obj as IProject).then((result: any) => {
        if (result?.error) {
          setMessage(`Error: ${result?.error}`);
          setMessageBarType(MessageBarType.error);
        } else {
          setMessage(`Project reactivated successfully`);
          setMessageBarType(MessageBarType.success);
        }
        setTimeout(resetMessage, 10000);
        hideReactivateModal();
      });
    }
  };
  const getItemAccessManagement = async () => {
    let contributors =
      project.contributor?.split(";") != undefined
        ? project.contributor?.split(";").filter(Boolean)
        : [];
    let owners =
      project.owner?.split(";") != undefined
        ? project.owner?.split(";").filter(Boolean)
        : [];
    let contributorsResult: any[] = [];
    let ownersResult: any[] = [];

    let allUsers = await graphService.getUserBatch(contributors.concat(owners));
    contributors?.forEach((item: any) => {
      let user = allUsers.filter((u: any) => {
        return u.mail == item;
      });
      if (user.length > 0)
        contributorsResult.push({ ...user[0], projectRole: "Member" });
    });
    owners?.forEach((item: any) => {
      let user = allUsers.filter((u: any) => {
        return u.mail == item;
      });
      if (user.length > 0)
        ownersResult.push({ ...user[0], projectRole: "Owner" });
    });
    setOwnersCount(owners.length);
    let resultSorted = ownersResult.concat(contributorsResult);
    setItemsAccessManagement(resultSorted);
    let currentSelectedUsers: IPersonaProps[] = [];
    resultSorted.forEach((element: { [x: string]: any }) => {
      let option = {
        key: element["mail"] || 0,
        text: element["displayName"] || "",
      };
      currentSelectedUsers.push(option);
    });
    setCurrentSelectedMembers(currentSelectedUsers);
  };

  const checkAllTac = (obj: any) => {
    if (obj?.recipients) {
      let result = obj.recipients.filter((e: any) => {
        return e.autoSendTermsCondition == true;
      });
      if (result.length == 0) {
        isCheckedTAC(false);
        isIndeterminateAutoTAC(false);
      } else {
        if (result.length == obj.recipients.length) {
          isCheckedTAC(true);
          isIndeterminateAutoTAC(false);
        } else {
          isCheckedTAC(false);
          isIndeterminateAutoTAC(true);
        }
      }
    }
  };
  const checkAllReport = (obj: any) => {
    if (obj?.recipients) {
      let result = obj.recipients.filter((e: any) => {
        return e.autoSendReport == true;
      });
      if (result.length == 0) {
        isCheckedReport(false);
        isIndeterminateAutoReport(false);
      } else {
        if (result.length == obj.recipients.length) {
          isCheckedReport(true);
          isIndeterminateAutoReport(false);
        } else {
          isCheckedReport(false);
          isIndeterminateAutoReport(true);
        }
      }
    }
  };
  const selectAllCheckBoxes = () => {
    if (selectAllcheckBoxReport) onChangeReport(undefined, checkedReport);
    if (selectAllcheckBoxTAC) onChangeTAC(undefined, checkedTAC);
  };
  const getCompanies = () => {
    serviceCommon.GetAllCompanies().then((result: any) => {
      if (result.error) {
        setMessage(`Error: ${result?.error}`);
        setMessageBarType(MessageBarType.error);
      } else {
        let ddpOptions: any[] = [];
        let res = result as ICommon[];
        if (res.length > 0) {
          res.forEach((element) => {
            ddpOptions.push({
              key: element.company?.companyCode,
              text: `${element.company?.country} / ${element.company?.companyName}`,
              title: element.company?.companyName,
              disabled: !element.company?.active,
            });
          });
          setCompanies(ddpOptions);
        } else {
          setMessage("No company is found.");
          setMessageBarType(MessageBarType.warning);
        }
      }
      setTimeout(resetMessage, 10000);
    });
  };
  useEffect(() => {
    const localCurrentUser = localStorage.getItem("currentUser");
    if (typeof localCurrentUser === "string") {
      const user = JSON.parse(localCurrentUser);
      setCurrentUser(user);
      if (projectId == "" && user.isProjectManager == false) {
        navigate("/notfound");
      }
    }
    getProject();
    getCompanies();
  }, []);

  useEffect(() => {
    if (
      currentUser != undefined &&
      project.projectId != undefined &&
      currentUser.isLegal == false
    ) {
      if (
        !(
          project.owner?.includes(currentUser?.email) ||
          project.contributor?.includes(currentUser?.email)
        )
      ) {
        navigate("/notfound");
      }
    }
    isShowPanel(
      (projectId == "" && currentUser?.isProjectManager == true) ||
        (projectId != "" &&
          (project.owner?.includes(currentUser?.email) ||
            project.contributor?.includes(currentUser?.email) ||
            currentUser?.isLegal))
    );
    isShowSaveBtn(
      (projectId == "" && currentUser?.isProjectManager == true) ||
        (projectId != "" &&
          (project.owner?.includes(currentUser?.email) ||
            project.contributor?.includes(currentUser?.email)) &&
          project.status == "Draft")
    );
    if (project.recipients != undefined && project.recipients.length > 0) {
      let selectedRecipients = project.recipients.filter(
        (e: IRecipientsManagement) => {
          return e.autoSendTermsCondition == true;
        }
      );
      setNumberSelectedRecipients(selectedRecipients.length);
    }
  }, [project, currentUser]);
  useEffect(() => {
    if (project.owner || project.contributor) {
      getItemAccessManagement();
    }
    if (project.recipients) {
      setItemsRecipientsManagement(project.recipients);
      checkAllTac(project);
      checkAllReport(project);
    }
    if (project.documents != undefined) {
      setReportDocumentManagement(project.documents);
    }
  }, [project]);
  useEffect(() => {
    if (project.company != null && companies.length > 0) {
      const company: any = companies.find((e) => {
        return e.key == project?.company;
      });
      if (company != null) setSelectedCompany(company.key);
    }
  }, [project, companies]);

  useEffect(() => {
    if (itemsRecipientsManagement)
      isDisableAllCheckboxReport(
        itemsRecipientsManagement.some((x) => x.acceptanceDate != undefined)
      );
  }, [itemsRecipientsManagement]);
  return (
    <>
      {showPanel && (
        <Stack>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal horizontalAlign="start">
              <Label className={styles.PageHeader}>
                {projectId == "" ? "New project" : "Project details"}
              </Label>
            </Stack>
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                text="Project list"
                className={styles.buttonNewProject}
                iconProps={projectListIcon}
                onClick={routeChange}
              />
            </Stack>
          </Stack>
          <Stack>
            <hr className={styles.horizontalLine}></hr>
          </Stack>
          {message != "" && (
            <Stack>
              <MessageBar
                messageBarType={messageBarType}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                onDismiss={resetMessage}
              >
                {message}
              </MessageBar>
            </Stack>
          )}

          <Stack horizontal verticalAlign="center">
            <Label className={styles.contentHeader}>General information</Label>
          </Stack>
          <Stack horizontal horizontalAlign="start">
            <TextField
              label="Project name"
              value={project?.name}
              required
              styles={textfieldStyles}
              onChange={(ev, val) => {
                if (project?.status == "Draft")
                  onChangeTxtField(ev, "name", val);
              }}
              disabled={projectId != "" && project.status != "Draft"}
              errorMessage={
                project?.name == "" || project.name == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
            />
            <Dropdown
              placeholder="Please select"
              label="Category"
              options={categories}
              styles={dropdownStyles}
              defaultSelectedKey={project?.category}
              required
              onChange={(ev, option) => {
                if (project?.status == "Draft")
                  onDropDownChange(ev, "category", option);
              }}
              disabled={projectId != "" && project.status != "Draft"}
              errorMessage={project?.category ? "" : errorMessageFieldRequired}
            />
            <Dropdown
              placeholder="Please select"
              label="RB company"
              options={companies.sort(function (a, b) {
                return a.text.localeCompare(b.text);
              })}
              styles={dropdownStyles}
              defaultSelectedKey={selectedCompany}
              selectedKey={selectedCompany}
              required
              id="Company"
              onChange={(ev, option) => {
                if (project?.status == "Draft")
                  onDropDownChange(ev, "company", option);
              }}
              disabled={projectId != "" && project?.status != "Draft"}
              errorMessage={project?.company ? "" : errorMessageFieldRequired}
            />
          </Stack>
          <Stack horizontal horizontalAlign="start">
            <TextField
              label="Client"
              id="Client"
              value={project?.client}
              required
              styles={textfieldStyles}
              onChange={(ev, val) => {
                if (project?.status == "Draft")
                  onChangeTxtField(ev, "client", val);
              }}
              disabled={projectId != "" && project?.status != "Draft"}
              errorMessage={project?.client ? "" : errorMessageFieldRequired}
            />
            <TextField
              label="Target name"
              id="Target"
              value={project?.target}
              required
              styles={textfieldStyles}
              onChange={(ev, val) => {
                if (project?.status == "Draft")
                  onChangeTxtField(ev, "target", val);
              }}
              disabled={projectId != "" && project?.status != "Draft"}
              errorMessage={project?.target ? "" : errorMessageFieldRequired}
            />
          </Stack>
          {projectId != "" && (
            <Stack>
              <Stack horizontal horizontalAlign="start">
                <Label>Status</Label>
              </Stack>
              <Stack
                horizontal
                horizontalAlign="start"
                style={{ minWidth: 100 }}
              >
                {project?.status == "Archived" && (
                  <>
                    <Label
                      disabled
                      className={styles.Archived}
                      style={{ marginRight: 10 }}
                    >
                      {project?.status}
                    </Label>
                    {(project.owner?.includes(currentUser?.email) ||
                      project.contributor?.includes(currentUser?.email)) && (
                      <TooltipHost
                        content="Reactivate project"
                        calloutProps={calloutProps}
                        styles={hostStyles}
                      >
                        <IconButton
                          iconProps={{
                            iconName: "PlaybackRate1xIcon",
                            styles: {
                              root: [
                                {
                                  selectors: {
                                    ">span": {
                                      verticalAlign: "top",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                          onClick={() => {
                            showReactivateModal();
                          }}
                          className={styles.actionIconButton}
                        />
                      </TooltipHost>
                    )}
                  </>
                )}
                {project?.status == "Active" && (
                  <>
                    <Label
                      disabled
                      className={styles.Active}
                      style={{ marginRight: 10 }}
                    >
                      {project?.status}
                    </Label>
                    {(project.owner?.includes(currentUser?.email) ||
                      project.contributor?.includes(currentUser?.email)) && (
                      <TooltipHost
                        content="Archive project"
                        calloutProps={calloutProps}
                        styles={hostStyles}
                      >
                        <IconButton
                          iconProps={{
                            iconName: "ArchiveIcon",
                            styles: {
                              root: [
                                {
                                  selectors: {
                                    ">span": {
                                      verticalAlign: "top",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                          onClick={() => {
                            showArchiveModal();
                          }}
                          className={styles.actionIconButton}
                        />
                      </TooltipHost>
                    )}
                  </>
                )}
                {project?.status == "Draft" && (
                  <Label disabled className={styles.Draft}>
                    {project?.status}
                  </Label>
                )}
              </Stack>
            </Stack>
          )}
          {showSaveBtn && (
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton
                text="Save"
                className={styles.buttonSave}
                onClick={onSubmitProject}
              />
            </Stack>
          )}
          {projectId != "" && (
            <>
              <Modal
                isOpen={isModalArchiveOpen}
                onDismiss={hideArchiveModal}
                isBlocking={false}
                containerClassName={contentStyles.archiveContainer}
              >
                <Stack style={{ padding: 20 }}>
                  <Label className={styles.contentHeader}>
                    Are you sure you want to archive this project?
                  </Label>

                  <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                      text="Confirm"
                      className={styles.buttonSave}
                      disabled={false}
                      onClick={() => onArchiveProject()}
                    />
                    <PrimaryButton
                      text="Cancel"
                      className={styles.buttonCancel}
                      onClick={hideArchiveModal}
                    />
                  </Stack>
                </Stack>
              </Modal>
              <Modal
                isOpen={isModalReactivateOpen}
                onDismiss={hideReactivateModal}
                isBlocking={false}
                containerClassName={contentStyles.archiveContainer}
              >
                <Stack style={{ padding: 20 }}>
                  <Label className={styles.contentHeader}>
                    Are you sure you want to reactivate this project?
                  </Label>

                  <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                      text="Confirm"
                      className={styles.buttonSave}
                      disabled={false}
                      onClick={() => onReactivateProject()}
                    />
                    <PrimaryButton
                      text="Cancel"
                      className={styles.buttonCancel}
                      onClick={hideReactivateModal}
                    />
                  </Stack>
                </Stack>
              </Modal>
              <Modal
                isOpen={isModalSendTacOrReportOpen}
                onDismiss={hideModalSendTacOrReport}
                isBlocking={false}
                containerClassName={contentStyles.archiveContainer}
              >
                <Stack style={{ padding: 20 }}>
                  {sentReport && (
                    <Label className={styles.contentHeader}>
                      You are about to send an email with report?
                    </Label>
                  )}
                  {sentTac && (
                    <Label className={styles.contentHeader}>
                      You are about to send an email with the terms and
                      conditions?
                    </Label>
                  )}
                  <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                      text="Confirm"
                      className={styles.buttonSave}
                      disabled={false}
                      onClick={() => {
                        sentReport
                          ? onSendNotificationOrReport(recipientItem, true)
                          : onSendNotificationOrReport(recipientItem, false);
                      }}
                    />
                    <PrimaryButton
                      text="Cancel"
                      className={styles.buttonCancel}
                      onClick={hideModalSendTacOrReport}
                    />
                  </Stack>
                </Stack>
              </Modal>
              <Stack>
                <hr className={styles.horizontalLine}></hr>
              </Stack>
              {messageReport != "" && (
                <Stack>
                  <MessageBar
                    messageBarType={messageBarTypeReport}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={resetMessageReport}
                  >
                    {messageReport}
                  </MessageBar>
                </Stack>
              )}
              <Stack horizontal verticalAlign="center">
                <Label className={styles.contentHeader}>Report documents</Label>
              </Stack>

              <Stack horizontal horizontalAlign="start">
                <PrimaryButton
                  text="Browse files"
                  onClick={() => {
                    hiddenFileInput.current.click();
                    hiddenFileInput.current.value = "";
                  }}
                  className={styles.buttonCancel}
                  label="Report"
                  disabled={project.status == "Archived"}
                />

                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={onFileInputChange}
                  accept={".pdf"}
                  style={{ display: "none" }}
                />
              </Stack>
              <Stack>
                <StackItem>
                  <DetailsList
                    items={
                      project.owner?.includes(currentUser?.email)
                        ? reportDocumentManagement
                        : reportDocumentManagement.filter(
                            (x) => x.isDisabled == false
                          )
                    }
                    compact={false}
                    columns={columnsReportDocument}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                  />
                </StackItem>
              </Stack>
              <Stack>
                <hr className={styles.horizontalLine}></hr>
              </Stack>
              {messagePermission != "" && (
                <Stack>
                  <MessageBar
                    messageBarType={messageBarTypePermission}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={resetMessagePermission}
                  >
                    {messagePermission}
                  </MessageBar>
                </Stack>
              )}
              <Stack horizontal horizontalAlign="space-between">
                <Label className={styles.contentHeader}>
                  Internal access management
                </Label>
                {projectId != "" &&
                  project.owner?.includes(currentUser?.email) && (
                    <TooltipHost
                      content="Add user permission"
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton
                        iconProps={{ iconName: "Add" }}
                        className={styles.btnAddItem}
                        onClick={showModal}
                        disabled={project?.status == "Archived"}
                      ></IconButton>
                    </TooltipHost>
                  )}
                <Modal
                  isOpen={isModalOpen}
                  onDismiss={resetModal}
                  isBlocking={false}
                  containerClassName={
                    deletePermission
                      ? contentStyles.archiveContainer
                      : contentStyles.container
                  }
                >
                  {deletePermission == false && (
                    <Stack style={{ padding: 20 }}>
                      {editPermission &&
                        itemsAccessManagementModal.length > 0 &&
                        currentUser?.email ==
                          itemsAccessManagementModal[0].mail && (
                          <MessageBar messageBarType={MessageBarType.warning}>
                            only owners can manage permissions. In case you
                            downgrade your permission you will not be able to
                            manage permission
                          </MessageBar>
                        )}
                      <Label className={styles.contentHeader}>
                        Internal access management -{" "}
                        {!editPermission
                          ? "Add user permission"
                          : "Edit user permission"}
                      </Label>
                      {!editPermission && (
                        <NormalPeoplePicker
                          onChange={onItemsChange}
                          onResolveSuggestions={onFilterChanged}
                          className={"ms-PeoplePicker"}
                          key={"normal"}
                          selectionAriaLabel={"Selected contacts"}
                          removeButtonAriaLabel={"Remove"}
                          resolveDelay={300}
                        />
                      )}

                      <DetailsList
                        items={itemsAccessManagementModal}
                        compact={false}
                        columns={columnsAccessManagementModal}
                        selectionMode={SelectionMode.none}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                      />
                      <Stack horizontal horizontalAlign="end">
                        <PrimaryButton
                          text={!editPermission ? "Add" : "Edit"}
                          className={styles.buttonSave}
                          disabled={false}
                          onClick={onAddProjectPermission}
                        />
                        <PrimaryButton
                          text="Cancel"
                          className={styles.buttonCancel}
                          onClick={resetModal}
                        />
                      </Stack>
                    </Stack>
                  )}
                  {deletePermission && (
                    <Stack style={{ padding: 20 }}>
                      <Label className={styles.contentHeader}>
                        Are you sure you want to delete{" "}
                        {deletePermissionItem.displayName}?
                      </Label>

                      <Stack horizontal horizontalAlign="end">
                        <PrimaryButton
                          text="Confirm"
                          className={styles.buttonSave}
                          disabled={false}
                          onClick={() =>
                            onDeleteProjectUserPermission(deletePermissionItem)
                          }
                        />
                        <PrimaryButton
                          text="Cancel"
                          className={styles.buttonCancel}
                          onClick={resetModal}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Modal>
              </Stack>
              <Stack>
                <StackItem>
                  <DetailsList
                    items={itemsAccessManagement.sort(function (a, b) {
                      return a.displayName.localeCompare(b.displayName);
                    })}
                    compact={false}
                    columns={columnsAccessManagement}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                  />
                </StackItem>
              </Stack>
              <Stack>
                <hr className={styles.horizontalLine}></hr>
              </Stack>
              {messageRecipient != "" && (
                <Stack>
                  <MessageBar
                    messageBarType={messageBarTypeRecipient}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={resetMessageRecipient}
                  >
                    {messageRecipient}
                  </MessageBar>
                </Stack>
              )}
              <Stack horizontal horizontalAlign="space-between">
                <Label className={styles.contentHeader}>
                  Recipient management
                </Label>
                {projectId != "" &&
                  (project.owner?.includes(currentUser?.email) ||
                    project.contributor?.includes(currentUser?.email)) && (
                    <TooltipHost
                      content="Add recipient"
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton
                        iconProps={{ iconName: "Add" }}
                        className={styles.btnAddItem}
                        onClick={showRecipientModal}
                        disabled={project?.status == "Archived"}
                      ></IconButton>
                    </TooltipHost>
                  )}
                <Modal
                  isOpen={isModalRecipientOpen}
                  isBlocking={false}
                  containerClassName={
                    selectAllcheckBoxReport ||
                    selectAllcheckBoxTAC ||
                    deleteRecipient
                      ? contentStyles.archiveContainer
                      : contentStyles.container
                  }
                  onDismiss={resetModalRecipient}
                >
                  {deleteRecipient && (
                    <Stack style={{ padding: 20 }}>
                      <Label className={styles.contentHeader}>
                        Are you sure you want to delete{" "}
                        {deleteRecipientItem.email}?
                      </Label>

                      <Stack horizontal horizontalAlign="end">
                        <PrimaryButton
                          text="Confirm"
                          className={styles.buttonSave}
                          onClick={() => onDeleteRecipient(deleteRecipientItem)}
                        />
                        <PrimaryButton
                          text="Cancel"
                          className={styles.buttonCancel}
                          onClick={resetModalRecipient}
                        />
                      </Stack>
                    </Stack>
                  )}
                  {!deleteRecipient && (
                    <Stack style={{ padding: 20 }}>
                      {!(selectAllcheckBoxReport || selectAllcheckBoxTAC) && (
                        <Label className={styles.contentHeader}>
                          Recipient management - Add new recipients
                        </Label>
                      )}
                      {(selectAllcheckBoxReport || selectAllcheckBoxTAC) && (
                        <Label className={styles.contentHeader}>
                          {selectAllcheckBoxReport
                            ? "Please note that selecting this checkbox all recipients will receive the report automatically through the system once the terms have been accepted"
                            : "Please note that selecting this checkbox all recipients will receive the terms and conditions once the process have been started"}
                        </Label>
                      )}
                      {!(selectAllcheckBoxReport || selectAllcheckBoxTAC) && (
                        <Stack horizontal>
                          <TextField
                            id="Recipient"
                            label=""
                            type="email"
                            style={{ width: 600, paddingRight: 30 }}
                            errorMessage={errorMessageEmail}
                            onChange={(ev, val) => {
                              onChangeTxtField(ev, "email", val);
                            }}
                            value={recipientEmail}
                          />
                          <TooltipHost
                            content="Add recipient"
                            calloutProps={calloutProps}
                            styles={hostStyles}
                          >
                            <IconButton
                              iconProps={{ iconName: "Add" }}
                              className={styles.btnAddItem}
                              onClick={addRecipient}
                              label=""
                            />
                          </TooltipHost>
                        </Stack>
                      )}
                      {!(selectAllcheckBoxReport || selectAllcheckBoxTAC) && (
                        <>
                          <DetailsList
                            items={recipientManagementModal}
                            compact={false}
                            columns={columnsRecipientsManagementModal}
                            selectionMode={SelectionMode.none}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                          />
                          <Stack horizontal horizontalAlign="end">
                            <PrimaryButton
                              text="Add"
                              className={styles.buttonSave}
                              disabled={recipientManagementModal.length == 0}
                              onClick={onAddRecipientToManagement}
                            />
                            <PrimaryButton
                              text="Cancel"
                              className={styles.buttonCancel}
                              onClick={resetModalRecipient}
                            />
                          </Stack>
                        </>
                      )}
                      {(selectAllcheckBoxReport || selectAllcheckBoxTAC) && (
                        <Stack horizontal horizontalAlign="end">
                          <PrimaryButton
                            text="Confirm"
                            className={styles.buttonSave}
                            onClick={selectAllCheckBoxes}
                          />
                          <PrimaryButton
                            text="Cancel"
                            className={styles.buttonCancel}
                            onClick={() => {
                              selectAllcheckBoxReport == true
                                ? isCheckedReport(false)
                                : isCheckedTAC(false);
                              resetModalRecipient();
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Modal>
              </Stack>
              <Stack>
                <StackItem>
                  <DetailsList
                    items={
                      itemsRecipientsManagement
                        ? itemsRecipientsManagement.filter((e) => {
                            return e.isDeleted != true;
                          })
                        : []
                    }
                    compact={false}
                    columns={columnsRecipientsManagement}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                  />
                </StackItem>
              </Stack>
              <Stack horizontal horizontalAlign="end">
                {project?.status == "Draft" && (
                  <PrimaryButton
                    text="Start process"
                    className={styles.buttonNewProject}
                    onClick={showProcessModal}
                    disabled={
                      project.recipients == null ||
                      project.recipients.length == 0
                    }
                  />
                )}
              </Stack>
              <Modal
                isOpen={isModalProcessOpen}
                onDismiss={hideProcessModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
              >
                <Stack style={{ padding: 20 }}>
                  {numberSelectedRecipients == 0 && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                      Please note that right now no recipients have been
                      selected for the automatic sent-out.
                    </MessageBar>
                  )}
                  <Label className={styles.contentHeader}>
                    You are about to start the Electronic Release Letter process
                    for Project {project.name}.
                  </Label>
                  {/* {numberSelectedRecipients == 0 && (
                    <Label>
                      Please note that right now no recipients have been
                      selected for the automatic sent-out.
                    </Label>
                  )} */}

                  <Stack horizontal horizontalAlign="start">
                    <TextField
                      label="Project name"
                      value={project?.name}
                      readOnly
                      styles={textfieldmodalStyles}
                      borderless
                    />
                    <TextField
                      label="Category"
                      styles={textfieldmodalStyles}
                      value={project?.category}
                      readOnly
                      borderless
                    />
                    <TextField
                      label="RB company"
                      styles={textfieldmodalStyles}
                      value={
                        companies.filter((c) => {
                          return c.key == project?.company;
                        }).length > 0
                          ? companies.filter((c) => {
                              return c.key == project?.company;
                            })[0].title
                          : ""
                      }
                      readOnly
                      id="Company"
                      borderless
                    />
                  </Stack>
                  <Stack horizontal horizontalAlign="start">
                    <TextField
                      label="Client"
                      id="Client"
                      value={project?.client}
                      styles={textfieldmodalStyles}
                      readOnly
                      borderless
                    />
                    <TextField
                      label="Target name"
                      id="Target"
                      value={project?.target}
                      styles={textfieldmodalStyles}
                      readOnly
                      borderless
                    />
                  </Stack>
                  {numberSelectedRecipients != 0 && (
                    <Label className={styles.contentHeader}>
                      With your confirmation the following recipients will
                      receive an invitation link to accept Roland Berger’s
                      document access terms.
                    </Label>
                  )}
                  <Stack>
                    {itemsRecipientsManagement &&
                      itemsRecipientsManagement.map(
                        (recipient: IRecipientsManagement) =>
                          recipient.autoSendTermsCondition ? (
                            <Label
                              style={{ fontWeight: 400 }}
                              key="recipient.recipientId"
                            >
                              {recipient.email}
                            </Label>
                          ) : (
                            ""
                          )
                      )}
                  </Stack>

                  <Label className={styles.contentHeader}>
                    Please confirm that this action is aligned with your team
                    and client.
                  </Label>

                  <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                      text="Confirm"
                      className={styles.buttonSave}
                      disabled={false}
                      onClick={onStartProcess}
                    />
                    <PrimaryButton
                      text="Cancel"
                      className={styles.buttonCancel}
                      onClick={hideProcessModal}
                    />
                  </Stack>
                </Stack>
              </Modal>
            </>
          )}
        </Stack>
      )}
    </>
  );
};
export default NewProject;
